var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "detail"
  }, [_c('div', {
    staticClass: "crumbs"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'billList',
        params: {
          status: 0
        }
      }
    }
  }, [_vm._v(" 我的账单 ")]), _vm._v(" / 账单详情 ")], 1), _c('div', {
    staticClass: "detail-content"
  }, [_vm.table.data.length > 0 ? _c('div', {
    staticClass: "content"
  }, [_c('section', {
    staticClass: "item"
  }, [_vm._m(0), _c('div', {
    staticClass: "item-body"
  }, [_c('div', {
    staticClass: "clearfix base"
  }, [_c('div', {
    staticClass: "distinction"
  }, [_c('div', {
    staticClass: "corner"
  }, [_vm._v("应还金额：")]), _c('span', {
    staticClass: "amount"
  }, [_vm._v(_vm._s(_vm.base.data.accountsPayable))]), _c('b-button', {
    staticClass: "button",
    on: {
      "click": _vm.goToPayment
    }
  }, [_vm._v("去支付")])], 1), _c('div', {
    staticClass: "fields"
  }, [_c('ul', {
    staticClass: "clearfix"
  }, [_c('li', [_c('span', {
    staticClass: "name"
  }, [_vm._v("账单周期：")]), _c('span', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.base.data.billCycle))])]), _c('li', [_c('span', {
    staticClass: "name"
  }, [_vm._v("销售金额：")]), _c('span', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.base.data.salesAmount))])]), _c('li', [_c('span', {
    staticClass: "name"
  }, [_vm._v("退货金额：")]), _c('span', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.base.data.returnAmount))])]), _c('li', [_c('span', {
    staticClass: "name"
  }, [_vm._v("最后还款日：")]), _c('span', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.base.data.lastRepaymentDate))])]), _c('li', [_c('span', {
    staticClass: "name"
  }, [_vm._v("单据号：")]), _c('span', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.base.data.documentNo))])]), _c('li', [_c('span', {
    staticClass: "name"
  }, [_vm._v("客户编码：")]), _c('span', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.base.data.customerCode))])])]), +_vm.payment.data.payStatus === 1 ? _c('ul', {
    staticClass: "clearfix"
  }, [_c('li', [_c('span', {
    staticClass: "name"
  }, [_vm._v("支付状态：")]), _c('span', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm._f("payStatusFilter")(_vm.payment.data.payStatus)) + " ")])]), _c('li', [_c('span', {
    staticClass: "name"
  }, [_vm._v("支付时间：")]), _c('span', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.payment.data.payTime))])]), _c('li', [_c('span', {
    staticClass: "name"
  }, [_vm._v("支付方式：")]), _c('span', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.payment.data.payWay))])]), _c('li', [_c('span', {
    staticClass: "name"
  }, [_vm._v("支付金额：")]), _c('span', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.payment.data.payAmount))])]), _c('li', [_c('span', {
    staticClass: "name"
  }, [_vm._v("支付流水号：")]), _c('span', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.payment.data.payTransactionNo) + " ")])])]) : _vm._e()])])])]), _c('section', {
    staticClass: "item"
  }, [_vm._m(1), _c('div', {
    staticClass: "item-body"
  }, [_c('div', {
    staticClass: "clearfix table-detail"
  }, [_c('b-table', {
    attrs: {
      "pagination-size": "is-small",
      "data": _vm.table.data,
      "striped": true,
      "hoverable": true,
      "bordered": false,
      "narrowed": false,
      "paginated": false
    },
    scopedSlots: _vm._u([{
      key: "empty",
      fn: function fn() {
        return [_c('div', {
          staticClass: "empty"
        }, [_vm._v("暂无数据")])];
      },
      proxy: true
    }], null, false, 3355316843)
  }, [_c('b-table-column', {
    attrs: {
      "field": "busTime",
      "label": "业务时间",
      "header-class": "th",
      "cell-class": "td"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(" " + _vm._s(props.row.busTime) + " ")];
      }
    }], null, false, 1004611948)
  }), _c('b-table-column', {
    attrs: {
      "field": "arrearsAmount",
      "label": "欠款金额",
      "header-class": "th",
      "cell-class": "td"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(" " + _vm._s(props.row.arrearsAmount) + " ")];
      }
    }], null, false, 2528194133)
  }), _c('b-table-column', {
    attrs: {
      "field": "remind",
      "label": "锁抬头提醒",
      "header-class": "th",
      "cell-class": "td"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(" " + _vm._s(props.row.remind) + " ")];
      }
    }], null, false, 67376644)
  }), _c('b-table-column', {
    attrs: {
      "field": "action",
      "label": "操作",
      "header-class": "th",
      "cell-class": "td"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "action"
        }, [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.modalDetailShow(props.row);
            }
          }
        }, [_vm._v("明细")])])];
      }
    }], null, false, 3129631557)
  })], 1)], 1)])])]) : _c('div', {
    staticClass: "empty"
  }, [_vm._m(2)])]), _c('b-modal', {
    attrs: {
      "custom-class": "modal-detail",
      "can-cancel": true,
      "has-modal-card": true,
      "destroy-on-hide": true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c('aside', {
          staticClass: "modal-card"
        }, [_c('header', {
          staticClass: "modal-card-head"
        }, [_c('h1', {
          staticClass: "modal-card-title"
        }, [_vm._v("明细信息")]), _c('button', {
          staticClass: "delete",
          attrs: {
            "type": "button"
          },
          on: {
            "click": props.close
          }
        })]), _c('div', {
          staticClass: "modal-card-body"
        }, [_c('div', {
          staticClass: "clearfix table-detail"
        }, [_c('b-table', {
          attrs: {
            "data": _vm.modalDetail.data,
            "striped": true,
            "hoverable": true,
            "bordered": false,
            "narrowed": false
          },
          scopedSlots: _vm._u([{
            key: "empty",
            fn: function fn() {
              return [_c('div', {
                staticClass: "empty"
              }, [_vm._v("暂无数据")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-table-column', {
          attrs: {
            "field": "documentNo",
            "label": "单据号",
            "header-class": "th",
            "cell-class": "td"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(props) {
              return [_vm._v(" " + _vm._s(props.row.documentNo) + " ")];
            }
          }], null, true)
        }), _c('b-table-column', {
          attrs: {
            "field": "documentType",
            "label": "单据类型",
            "header-class": "th",
            "cell-class": "td"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(props) {
              return [_vm._v(" " + _vm._s(props.row.documentType) + " ")];
            }
          }], null, true)
        }), _c('b-table-column', {
          attrs: {
            "field": "amount",
            "label": "金额（元）",
            "header-class": "th",
            "cell-class": "td"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(props) {
              return [_vm._v(" " + _vm._s(props.row.amount) + " ")];
            }
          }], null, true)
        }), _c('b-table-column', {
          attrs: {
            "field": "busTime",
            "label": "业务时间",
            "header-class": "th",
            "cell-class": "td"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(props) {
              return [_vm._v(" " + _vm._s(props.row.busTime) + " ")];
            }
          }], null, true)
        })], 1)], 1)])])];
      }
    }]),
    model: {
      value: _vm.modalDetail.show,
      callback: function callback($$v) {
        _vm.$set(_vm.modalDetail, "show", $$v);
      },
      expression: "modalDetail.show"
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('header', {
    staticClass: "clearfix item-header"
  }, [_c('h1', [_vm._v("账单信息")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('header', {
    staticClass: "clearfix item-header"
  }, [_c('h1', [_vm._v("账单明细")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "empty-container"
  }, [_c('img', {
    staticClass: "image",
    attrs: {
      "src": require("./images/empty.png"),
      "alt": "空"
    }
  }), _c('p', {
    staticClass: "desc"
  }, [_vm._v("暂无数据")])]);
}];
export { render, staticRenderFns };